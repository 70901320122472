import React, { useState } from 'react';

function VisitDetails({ visitDetails }) {
  if (!visitDetails.visitId ||!visitDetails.time) {
    return <div></div>;
  }

  return (
    <div>
      <p>Visit ID: {visitDetails.visitId}</p>
      <p>Time: {visitDetails.time}</p>
    </div>
  );
}

function NumericInput() {
  const [value, setValue] = useState('');
  const [visitDetails, setVisitDetails] = useState([]);
  const [history, setHistory] = useState([]);

  function isValidInput(value) {
    return value === '' || /^[0-9]+$/.test(value);
  }

  function handleChange(setValue, event) {
    const value = event.target.value;
    if (isValidInput(value)) {
      setValue(value);
    }
  }

  function handleSubmit(value, setVisitDetails) {
    fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/visits/${value}`)
    .then(response => response.json())
    .then(data => {
        console.log(data);
        setVisitDetails([...visitDetails, data]);
        setHistory([...history, { visitId: data.visitId, time: data.time }]);
      })
    .catch(error => console.error('Error:', error));
  }

  return (
    <div>
      <h2>Retrieve visit details here:</h2>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit(value, setVisitDetails);
        }}
      >
        <input type="text" value={value} onChange={(event) => handleChange(setValue, event)} placeholder="Enter visit ID here" />
        <button type="submit">Submit</button>
      </form>
      <VisitDetails visitDetails={visitDetails} />
      <h3>History</h3>
      <table>
        <thead>
          <tr>
            <th>Visit ID</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {history.map((item, index) => (
            <tr key={index}>
              <td>{item.visitId}</td>
              <td>{new Date(item.time).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default NumericInput;