import { useState, useEffect } from 'react';

const ApiPost = () => {
  const [totalVisits, setTotalVisits] = useState(0);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/visits`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ /* your POST request body */ }),
    })
      .then(response => response.json())
      .then(data => {
        setTotalVisits(data.totalVisits);
      })
      .catch((error) => console.error('Error:', error));
  }, []);

  return totalVisits;
};

export default ApiPost;